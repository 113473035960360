export const handleActionKeyPress = (event, callback = () => {}, ...args) => {
  console.log("Key Pressed:", event.key);
  if (event.key === 'Enter' || event.key === ' ') {
    callback(...args);
  }
};

export const handleFocus = (e) => {
  e.target.style.outline = '2px solid #007bff';
  e.target.style.outlineOffset = '2px';
};

export const handleBlur = (e) => {
  e.target.style.outline = 'none';
};
