import logoIsos from '../assets/images/logo-isos.svg';
import vitalStep from '../assets/images/vital_step.svg'
import ekincareFullLogo from '../assets/images/ekincarefulllogo.svg';
import { PRIVACY_POLICY } from '../routes/public';

export const API = {
  BASE_URL: process.env.REACT_APP_BASE_URL || 'https://app-beta.ekincare.com'
}

const FITBIT_CLIENT_ID = process.env.REACT_APP_FITBIT_CLIENT_ID || '22CLPW'
const STRAVA_CLIENT_ID = process.env.REACT_APP_STRAVA_CLIENT_ID || '23281'

// TODO :: Check `WEARABLES_CALLBACK` import is not accessible here. Don't know why?
export const WEARABALES_REDIRECT_URI = process.env.REACT_APP_WEARABALES_REDIRECT_URI || (window.isEkincareApp() ? `${window.location.origin}/wearables/callback-new` : `${window.location.origin}/wearables/callback-new`)

export const WEARABLE_DEVICES_API = {
  FITBIT: `https://www.fitbit.com/oauth2/authorize?response_type=code&client_id=${FITBIT_CLIENT_ID}&redirect_uri=${WEARABALES_REDIRECT_URI}&scope=activity heartrate sleep weight`,
  STRAVA: `https://www.strava.com/oauth/authorize?&client_id=${STRAVA_CLIENT_ID}&response_type=code&redirect_uri=${WEARABALES_REDIRECT_URI}&scope=activity:read_all&state=web&approval_prompt=force`
}

export const customerDataInitialState = {
  'all_bookings': {
    'nextPage': 1,
    'data': [],
    'currentPage': 1
  },
  'health_checks': {
    'nextPage': 1,
    'data': [],
    'currentPage': 1
  },

  'fitness': {
    'nextPage': 1,
    'data': [],
    'currentPage': 1

  },

  'pharmacy_orders': {
    'nextPage': 1,
    'data': [],
    'currentPage': 1

  },

  'medical_history': {
    'nextPage': 1,
    'data': [],
    'currentPage': 1

  },

  'documents': {
    'nextPage': 1,
    'data': [],
    'currentPage': 1

  },

  'health_coach': {
    'nextPage': 1,
    'data': [],
    'currentPage': 1
  },

  'consultations': {
    'nextPage': 1,
    'data': [],
    'currentPage': 1
  }
}

export const CANCELLATION_REASONS = [
  'Personal Emergency',
  'Health No Longer a Priority',
  'Not Feeling Well',
  'Traveling/Relocated',
  'Cost Concerns',
  'Dissatisfied with Previous Service',
  'Inconvenient Time or Date',
  'Lack of Information',
  'Appointment not confirmed',
  'Unable to Find Clinic',
  'Other reason'
]

export const BOOKING_HISTORY_SERVICES = {
  'health_checks': 'Health Check',
  'pharmacy': 'Pharmacy',
  'consultations': 'Consultation',
  'fitness': 'Gym',
}

export const BOOKING_HISTORY_STATUS = {
  'on_going': 'On-Going',
  'completed': 'Completed',
  'cancelled': 'Cancelled'
}

export const AFTER_CONFIRM_APPOINTMENT_STATUS = ['confirmed', 'received', 'completed', 'verification_required', 'partially_received']

export const EMAILREGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const WEARABLE_TROUBLESHOOTING_GUIDE_PDF = 'https://res.cloudinary.com/ekincare/image/upload/v1653980876/Troubleshoot_guide_PDF_for_wearables_A4_1.pdf'

export const healthChecksList = ["ahc", "nhc", "vision", "dental"];

export const consultationList = ["specialist_consultation", "inclinic_consultation", "consultation24x7", "eap"];

export const targetName = window.localStorage.getItem('TARGET-NAME')

export const isNativePaymentDisabled = window.localStorage.getItem('disableNativePayment')

export const ISOS = 'isos';

export const VITALSTEP = 'vitalstep';

export const ISOS_PRIVACY_POLICY = 'https://www.internationalsos.com/privacy-centre/member'

export const VITALSTEP_PRIVACY = 'https://www.alineahealthcare.in/Home/legal'

export const EKINCARE_TERMS = 'https://www.ekincare.com/about/terms'

const companyNames = { isos: 'International SOS', 'vitalstep': 'vitalstep\u2019s', 'healthcore360.ekincare.com':'International SOS' };

export const companyName = companyNames[targetName]|| companyNames[window.location.host] || 'ekincare\u2019s';

export const ISOS_HOST = 'healthcore360.ekincare.com'

export const ALL_COUNTRIES_ISD_CODES = [
  { id: '+91', name: '+91 - India' },
  { id: '+60', name: '+60 - Malaysia' },
  { id: '+65', name: '+65 - Singapore' },
  { id: '+63', name: '+63 - Philippines'},
  { id: '+84', name: '+84 - Vietnam' },
  { id: '+976', name: '+976 - Mongolia' },
  { id: '+95', name: '+95 - Myanmar' }
]

export const benefitColors = {
  'bg-pink-50': ['ahc'],
  'bg-green-50': ['weight_management'],
  'bg-red-50': ['vision', 'inclinic_consultation', 'face_to_face_consults'],
  'bg-blue-50': ['reimbursement', 'eap', 'diabetes', 'covid_vaccination', 'elder_care', 'ambulance_on_demand', 'supplementary_insurance'],
  'bg-indigo-50': ['fitness', 'diet_consultation', 'daycare_surgeries', 'lab_tests', 'ohc', 'nhc', 'other_health_checks', 'radiology', 'healthcare_financing', 'imaging_services'],
  'bg-yellow-50': ['dental', 'specialist_consultation', 'pharmacy', 'hypertension', 'family_doctor', 'consultation24x7', 'flu_vaccination', 'fertility_treatment','health_insurance', 'rtpcr'],
};

export const BENEFITS_ICON_URL_PNG = {

  ahc: 'https://res.cloudinary.com/ekincare/image/upload/v1698652419/benefits/pngCompressed/ahc.png',

  ambulance_on_demand: 'https://res.cloudinary.com/ekincare/image/upload/v1698652424/benefits/pngCompressed/ambulance_on_demand.png',

  consultation24x7: 'https://res.cloudinary.com/ekincare/image/upload/v1698652425/benefits/pngCompressed/consultation24x7.png',

  family_doctor: 'https://res.cloudinary.com/ekincare/image/upload/v1698652425/benefits/pngCompressed/consultation24x7.png',

  covid_vaccination: 'https://res.cloudinary.com/ekincare/image/upload/v1698652442/benefits/pngCompressed/covid_vaccination.png',

  daycare_surgeries: 'https://res.cloudinary.com/ekincare/image/upload/v1698652430/benefits/pngCompressed/daycare_surgeries.png',

  dental: 'https://res.cloudinary.com/ekincare/image/upload/v1698652428/benefits/pngCompressed/dental.png',

  diet_consultation: 'https://res.cloudinary.com/ekincare/image/upload/v1698652416/benefits/pngCompressed/diet_consultation.png',

  eap: 'https://res.cloudinary.com/ekincare/image/upload/v1698652426/benefits/pngCompressed/eap.png',

  elder_care: 'https://res.cloudinary.com/ekincare/image/upload/v1698652421/benefits/pngCompressed/elder_care.png',

  fertility_treatment: 'https://res.cloudinary.com/ekincare/image/upload/v1698652415/benefits/pngCompressed/fertility_treatment.png',

  fitness: 'https://res.cloudinary.com/ekincare/image/upload/v1698652438/benefits/pngCompressed/fitness.png',

  flu_vaccination: 'https://res.cloudinary.com/ekincare/image/upload/v1698652417/benefits/pngCompressed/flu_vaccination.png',

  health_insurance: 'https://res.cloudinary.com/ekincare/image/upload/v1698652418/benefits/pngCompressed/health_insurance.png',

  healthcare_financing: 'https://res.cloudinary.com/ekincare/image/upload/v1698652434/benefits/pngCompressed/healthcare_financing.png',

  pharmacy: 'https://res.cloudinary.com/ekincare/image/upload/v1698652435/benefits/pngCompressed/pharmacy.png',

  radiology: 'https://res.cloudinary.com/ekincare/image/upload/v1698652440/benefits/pngCompressed/radiology.png',

  reimbursement: 'https://res.cloudinary.com/ekincare/image/upload/v1698652441/benefits/pngCompressed/reimbursements.png',

  rtpcr: 'https://res.cloudinary.com/ekincare/image/upload/v1698652431/benefits/pngCompressed/rtpcr.png',

  specialist_consultation: 'https://res.cloudinary.com/ekincare/image/upload/v1698652432/benefits/pngCompressed/specialist_consultation.png',

  supplementary_insurance: 'https://res.cloudinary.com/ekincare/image/upload/v1698652437/benefits/pngCompressed/supplementary_insurance.png',

  vision: 'https://res.cloudinary.com/ekincare/image/upload/v1698652414/benefits/pngCompressed/vision.png',

  weight_management: 'https://res.cloudinary.com/ekincare/image/upload/v1698652433/benefits/pngCompressed/weight_management.png',

  hypertension: 'https://res.cloudinary.com/ekincare/image/upload/v1698652427/benefits/pngCompressed/hypertension.png',

  diabetes: 'https://res.cloudinary.com/ekincare/image/upload/v1698652413/benefits/pngCompressed/diabetes.png',

  inclinic_consultation: 'https://res.cloudinary.com/ekincare/image/upload/v1698652436/benefits/pngCompressed/face_to_face_consults.png',

  face_to_face_consults: 'https://res.cloudinary.com/ekincare/image/upload/v1698652436/benefits/pngCompressed/face_to_face_consults.png',

  lab_tests: 'https://res.cloudinary.com/ekincare/image/upload/v1698652420/benefits/pngCompressed/other_health_checks.png',
  
  nhc: 'https://res.cloudinary.com/ekincare/image/upload/v1698652420/benefits/pngCompressed/other_health_checks.png',

  ohc: 'https://res.cloudinary.com/ekincare/image/upload/v1698652420/benefits/pngCompressed/other_health_checks.png',

  other_health_checks: 'https://res.cloudinary.com/ekincare/image/upload/v1698652420/benefits/pngCompressed/other_health_checks.png',

  dummy_icon: 'https://res.cloudinary.com/ekincare/image/upload/v1698652422/benefits/pngCompressed/no_icon.png',

  imaging_services: 'https://res.cloudinary.com/ekincare/image/upload/v1698652440/benefits/pngCompressed/radiology.png',

  occupational_medical_rooms: 'https://res.cloudinary.com/ekincare/image/upload/v1704278196/benefits/pngCompressed/occupational_medical_rooms.png',

  cancer_screening: 'https://res.cloudinary.com/ekincare/image/upload/v1709090869/benefits/pngCompressed/cancer_screening.png'

}

export const BENEFITS_ICON_URL_WEBP = {

  ahc: 'https://res.cloudinary.com/ekincare/image/upload/v1698651590/benefits/webpCompressed/ahc.webp',

  ambulance_on_demand: 'https://res.cloudinary.com/ekincare/image/upload/v1698651580/benefits/webpCompressed/ambulance_on_demand.webp',

  consultation24x7: 'https://res.cloudinary.com/ekincare/image/upload/v1698651594/benefits/webpCompressed/consultation24x7.webp',

  family_doctor: 'https://res.cloudinary.com/ekincare/image/upload/v1698651594/benefits/webpCompressed/consultation24x7.webp',

  covid_vaccination: 'https://res.cloudinary.com/ekincare/image/upload/v1698651592/benefits/webpCompressed/covid_vaccination.webp',

  daycare_surgeries: 'https://res.cloudinary.com/ekincare/image/upload/v1698651578/benefits/webpCompressed/daycare_surgeries.webp',

  dental: 'https://res.cloudinary.com/ekincare/image/upload/v1698651581/benefits/webpCompressed/dental.webp',

  diet_consultation: 'https://res.cloudinary.com/ekincare/image/upload/v1698651583/benefits/webpCompressed/diet_consultation.webp',

  eap: 'https://res.cloudinary.com/ekincare/image/upload/v1698651584/benefits/webpCompressed/eap.webp',

  elder_care: 'https://res.cloudinary.com/ekincare/image/upload/v1698651578/benefits/webpCompressed/elder_care.webp',

  fitness: 'https://res.cloudinary.com/ekincare/image/upload/v1698651595/benefits/webpCompressed/fitness.webp',

  flu_vaccination: 'https://res.cloudinary.com/ekincare/image/upload/v1698651579/benefits/webpCompressed/flu_vaccination.webp',

  health_insurance: 'https://res.cloudinary.com/ekincare/image/upload/v1698651582/benefits/webpCompressed/health_insurance.webp',

  pharmacy: 'https://res.cloudinary.com/ekincare/image/upload/v1698651591/benefits/webpCompressed/pharmacy.webp',

  radiology: 'https://res.cloudinary.com/ekincare/image/upload/v1698651585/benefits/webpCompressed/radiology.webp',

  reimbursement: 'https://res.cloudinary.com/ekincare/image/upload/v1698651590/benefits/webpCompressed/reimbursements.webp',

  rtpcr: 'https://res.cloudinary.com/ekincare/image/upload/v1698651578/benefits/webpCompressed/rtpcr.webp',

  specialist_consultation: 'https://res.cloudinary.com/ekincare/image/upload/v1698651582/benefits/webpCompressed/specialist_consultation.webp',

  vision: 'https://res.cloudinary.com/ekincare/image/upload/v1698651592/benefits/webpCompressed/vision.webp',

  weight_management: 'https://res.cloudinary.com/ekincare/image/upload/v1698651593/benefits/webpCompressed/weight_management.webp',

  hypertension: 'https://res.cloudinary.com/ekincare/image/upload/v1698651587/benefits/webpCompressed/hypertension.webp',

  diabetes: 'https://res.cloudinary.com/ekincare/image/upload/v1698651587/benefits/webpCompressed/diabetes.webp',

  inclinic_consultation: 'https://res.cloudinary.com/ekincare/image/upload/v1698651588/benefits/webpCompressed/face_to_face_consults.webp',

  face_to_face_consults: 'https://res.cloudinary.com/ekincare/image/upload/v1698651588/benefits/webpCompressed/face_to_face_consults.webp',

  lab_tests: 'https://res.cloudinary.com/ekincare/image/upload/v1698651594/benefits/webpCompressed/other_health_checks.webp',

  nhc: 'https://res.cloudinary.com/ekincare/image/upload/v1698651594/benefits/webpCompressed/other_health_checks.webp',

  ohc: 'https://res.cloudinary.com/ekincare/image/upload/v1698651594/benefits/webpCompressed/other_health_checks.webp',

  other_health_checks: 'https://res.cloudinary.com/ekincare/image/upload/v1698651594/benefits/webpCompressed/other_health_checks.webp',

  fertility_treatment: 'https://res.cloudinary.com/ekincare/image/upload/v1698651589/benefits/webpCompressed/fertility_treatment.webp',

  healthcare_financing: 'https://res.cloudinary.com/ekincare/image/upload/v1698651584/benefits/webpCompressed/healthcare_financing.webp',

  supplementary_insurance: 'https://res.cloudinary.com/ekincare/image/upload/v1698651578/benefits/webpCompressed/supplementary_insurance_1.webp',

  dummy_icon: 'https://res.cloudinary.com/ekincare/image/upload/v1698651586/benefits/webpCompressed/no_icon.webp',

  imaging_services: 'https://res.cloudinary.com/ekincare/image/upload/v1698651585/benefits/webpCompressed/radiology.webp',

  occupational_medical_rooms: 'https://res.cloudinary.com/ekincare/image/upload/v1704277730/benefits/webpCompressed/occupational_medical_rooms.webp',

  cancer_screening: 'https://res.cloudinary.com/ekincare/image/upload/v1709090816/benefits/webpCompressed/cancer_screening.webp'

}

export const ekincareLogo = 'https://res.cloudinary.com/ekincare/image/upload/v1700646080/home_screen_icons/ekincare_home.svg'

export const APP_LOGOS = {
  isos: logoIsos,
  vitalstep: vitalStep,
  ekincare: ekincareFullLogo,
  'healthcore360.ekincare.com' : logoIsos
}

export const APP_PRIVACY = {
  isos : ISOS_PRIVACY_POLICY,
  vitalstep : VITALSTEP_PRIVACY,
  ekincare : PRIVACY_POLICY,
  'healthcore360.ekincare.com' : ISOS_PRIVACY_POLICY
}

export const APP_TERMS = {
  isos : '/isos-terms',
  vitalstep : VITALSTEP_PRIVACY,
  ekincare : EKINCARE_TERMS
}

export const MOENGAGE_KNOWN_BENEFITS = ['pharmacy', 'ahc', 'dental', 'lab_tests', 'nhc', 'vision', 'eap', 'consultation24x7', 'family_doctor', 'inclinic_consultation', 'specialist_consultation', 'fertility_treatment', 'flu_vaccination', 'fitness', 'ambulance_on_demand'] 
